module.exports = {
  ENABLE_DEBUG_LOGGING: true,
  DISABLE_BQ_LOGGING: false,
  DISABLE_VIEWER_LEGACY_LOGGING: true,
  SERVER_URL: "https://rvacore-test.appspot.com",
  APPS_URL: "https://apps-stage-0.risevision.com",
  PREVIEW_TRACKER_ID: "UA-82239-27",
  DISPLAY_TRACKER_ID: "UA-82239-31",
  SHARED_SCHEDULE_TRACKER_ID: "UA-82239-45",
  MESSAGING_SERVER_URL: "https://services-stage.risevision.com/messaging/primus",
  LOCAL_MESSAGING_SERVER_URL: "http://localhost:8080",
  LOCAL_MESSAGING_CLIENT_NAME: "ws-client",
  SCREEN_SHARING_URL: "https://widgets.risevision.com/apps/screen-sharing/stage/client.html",
  CHROME_OS_PWA_PLAYER_EXTENSION_ID: "khbhjpcllgnkeliogmlbkpmoihohcfkp",
  HTML_TEMPLATE_ENV: "staging",
  TESTING_COMPANIES: null
};
